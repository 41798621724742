import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import Layout from "./pages/Layout";
import Home from "./pages/home/Home";
import Packages from "./pages/Packages";
import Destinations from "./pages/Destinations";
import Services from "./pages/Services";
import Insurance from "./pages/Insurance";
import Contact from "./pages/Contact";
import Specials from "./pages/Specials";
import TripPlan from "./pages/TripPlan";
import Booking from "./pages/Booking";
import Hotel from "./pages/hotel/Hotel";
import Cruise from "./pages/Cruise";
import Cars from "./pages/Cars";
import Details from "./pages/Details";
import SearchResultsList from "./pages/SearchResultsList";
import List from "./pages/list/List";
import Login from "./pages/login/Login";
import Package from "./components/Package/Package";
import Destination from "./components/Destination/Destination";
import Special from "./components/Special/Special";
import Service from "./components/Service/Service";

const App = () => {

  React.useEffect(() => {
    AOS.init({
      offset: 100,
      duration: 900,
      easing: "ease-in-sine",
      delay: 100,
    });
    AOS.refresh();
  }, []);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/Packages" element={<Package/>}/>
            <Route path="/Destinations" element={<Destination/>}/>
            <Route path="/Specials" element={<Special/>}/>
            <Route path="/Insurance" element={<Insurance/>}/>
            <Route path="/Services" element={<Service/>}/>
            <Route path="/TripPlan" element={<TripPlan/>}/>
            <Route path="/Contact" element={<Contact/>}/>
            <Route path="/Booking/:id" element={<Booking/>}/>
            <Route path="/hotels" element={<List/>}/>
            <Route path="/Cruise" element={<Cruise/>}/> 
            <Route path="/Cars" element={<Cars/>}/>
            <Route path="/hotels/:id" element={<Hotel/>}/>
            <Route path="/Details/:id" element={<Details/>}/>
            <Route path="/SearchResultsList" element={<SearchResultsList/>}/>
            <Route path="/login" element={<Login/>}/>
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
