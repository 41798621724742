import React from "react";
import FooterLogo from "../../assets/logo.png";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaLocationArrow,
  FaMobileAlt,
  FaEnvelope,
  FaWhatsapp
} from "react-icons/fa";
import NatureVid from "../../assets/video/footer.mp4";
import { Link } from "react-router-dom";

const SiteLinks = [
  {
    title: "Home",
    link: "/",
  },
  {
    title: "Packages",
    link: "/Packages",
  },
  {
    title: "Contact",
    link: "/Contact",
  },
];

const TravelLinks = [
  {
    title: "Destinations",
    link: "/Destinations",
  },
  {
    title: "Specials",
    link: "/Specials",
  },
  {
    title: "Insurance",
    link: "/Insurance",
  },
];

const QuickLinks = [
  {
    title: "Our Services",
    link: "/Services",
  },
  {
    title: "Plan your trip",
    link: "/TripPlan",
  },
];


const Footer = () => {
  return (
    <>
      <footer className=" dark:bg-gray-950 py-10 relative bottom-0 overflow-hidden">
        <video
          autoPlay
          loop
          muted
          className="absolute right-0 top-0 h-full overflow-hidden w-full object-cover z-[-1]"
        >
          <source src={NatureVid} type="video/mp4" />
        </video>
        <div className="container">
          <div className="grid md:grid-cols-3 py-5 bg-white/80 backdrop-blur-sm rounded-t-xl">
            <div className="py-8 px-4">
              <h1 className="flex items-center gap-3 text-xl sm:text-3xl font-bold text-justify sm:text-left mb-1">
                <img src={FooterLogo} alt="" className="max-h-[54px] w-32 h-11" />
                {/* TravelloGo */}
              </h1>
              <p className="text-sm">
                Trendy Travel Trips by Trendy Dialog is an international travel agency.
                We aim to be one of the leading travel technology companies with
                convenient processes for our clients and vendors.
              </p>
              <br />
              <div className="flex items-center gap-3 ">
                <span className='inline-flex gap-2'><FaLocationArrow className='h-6 w-5 mt-1 text-[#EA9937]'/> <span className='w-61'>13 The Manhattan LifeStyle Estate, Lenchen Avenue, Centurion,<br/> Gauteng, 0157</span> </span><br/>       
              </div>
              <div className="flex items-center gap-3 mt-3">
                <span className='inline-flex gap-2 leading-10'><FaMobileAlt className='h-6 w-5 mt-2 text-[#EA9937]'/> <span> +27 73 559 2210 </span></span><br/>        
              </div>
              <div className="flex items-center gap-3 mt-3">
                <span className='inline-flex gap-2 leading-10'><FaEnvelope className='h-6 w-5 mt-2 text-[#EA9937]'/> <span> admin@trendydialog.com  </span></span><br/>
              </div>
              {/* social handles */}           
              <div>
                <div className="flex items-center gap-3 mt-6">
                  <a href="#">
                    <FaInstagram className="text-3xl text-[#EA9937] hover:text-black" />
                  </a>
                  <a href="#">
                    <FaFacebook className="text-3xl text-[#EA9937] hover:text-black" />
                  </a>
                  <a href="#">
                    <FaLinkedin className="text-3xl text-[#EA9937] hover:text-black" />
                  </a>
                  <a href="#">
                    <FaWhatsapp className="text-3xl text-[#EA9937] hover:text-black" />
                  </a>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 sm:grid-cols-3 col-span-2 md:pl-10">
              <div>
                <div className="py-8 px-4">
                  <h1 className="text-xl font-bold text-justify sm:text-left mb-3">
                    Site Links
                  </h1>
                  <ul className="flex flex-col gap-3">
                    {SiteLinks.map((link) => (
                      <li className="cursor-pointer hover:translate-x-1 duration-300 hover:!text-primary space-x-1 text-gray-700 dark:text-gray-200">
                        <Link
                          to={link.link}
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          <span>&#11162;</span>
                          <span>{link.title}</span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div>
                <div className="py-8 px-4">
                  <h1 className="text-xl font-bold text-justify sm:text-left mb-3">
                    Travel Links
                  </h1>
                  <ul className="flex flex-col gap-3">
                    {TravelLinks.map((link) => (
                      <li className="cursor-pointer hover:translate-x-1 duration-300 hover:!text-primary space-x-1 text-gray-700 dark:text-gray-200">
                        <Link
                          to={link.link}
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          <span>&#11162;</span>
                          <span>{link.title}</span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div>
                <div className="py-8 px-4">
                  <h1 className="text-xl font-bold text-justify sm:text-left mb-3">
                    Quick Links
                  </h1>
                  <ul className="flex flex-col gap-3">
                    {QuickLinks.map((link) => (
                      <li className="cursor-pointer hover:translate-x-1 duration-300 hover:!text-primary space-x-1 text-gray-700 dark:text-gray-200">
                        <Link
                          to={link.link}
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          <span>&#11162;</span>
                          <span>{link.title}</span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="text-center py-5 border-t-2 border-gray-300/50 bg-primary text-white">
              @copyright 2024 All rights reserved || Made with ❤️ by Trendy Dialog
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
