import React from 'react';
import useFetch from '../hooks/useFetch';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useParams, useNavigate } from 'react-router-dom';

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} bg-gray-800 text-white rounded-full p-2`}
      style={{ ...style, display: 'block', right: '10px', zIndex: 1 }}
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        className="w-6 h-6"
      >
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
      </svg>
    </div>
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} bg-gray-800 text-white rounded-full p-2`}
      style={{ ...style, display: 'block', left: '10px', zIndex: 1 }}
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        className="w-6 h-6"
      >
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
      </svg>
    </div>
  );
};

const Details = () => {
    const { id } = useParams();   
    const navigate = useNavigate();
    const { data, loading, error } = useFetch(`${process.env.REACT_APP_API_URL}/packages/find/${id}`);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;
  
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
    };

    const handleBookClick = () => {
      navigate(`/booking/${id}`, { state: { packageData: data } });
    };
  
    return (
      <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg">
        <h2 className="text-2xl font-bold mb-4">{data.title}</h2>
        <p className="mb-4">{data.desc}</p>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-bold text-gray-700">Type</label>
            <p className="mt-1 text-gray-900">{data.type}</p>
          </div>
          <div>
            <label className="block text-sm font-bold text-gray-700">Country</label>
            <p className="mt-1 text-gray-900">{data.country}</p>
          </div>
          <div>
            <label className="block text-sm font-bold text-gray-700">City</label>
            <p className="mt-1 text-gray-900">{data.city}</p>
          </div>
          <div>
            <label className="block text-sm font-bold text-gray-700">Duration</label>
            <p className="mt-1 text-gray-900">{data.duration} days</p>
          </div>
          <div>
            <label className="block text-sm font-bold text-gray-700">From Date</label>
            <p className="mt-1 text-gray-900">{data.fromDate}</p>
          </div>
          <div>
            <label className="block text-sm font-bold text-gray-700">To Date</label>
            <p className="mt-1 text-gray-900">{data.toDate}</p>
          </div>
          <div>
            <label className="block text-sm font-bold text-gray-700">Max Guests</label>
            <p className="mt-1 text-gray-900">{data.maxGuests}</p>
          </div>
          <div>
            <label className="block text-sm font-bold text-gray-700">Min Age</label>
            <p className="mt-1 text-gray-900">{data.minAge}</p>
          </div>
          <div className="col-span-2">
            <label className="block text-sm font-bold text-gray-700">Includes</label>
            <ul className="mt-1 text-gray-900 list-disc list-inside">
            {/* {data.includes && Array.isArray(data.includes) ? (
              data.includes.map((item, index) => (
                <li key={index}>{item}</li>
              ))
            ) : (
              <li>No includes available</li>
            )} */}
          </ul>
          </div>
          <div className="col-span-2">
            <label className="block text-sm font-bold text-gray-700">Photos</label>
            {/* <Slider {...settings} className="mt-1">
              {data.photos.map((photo, index) => (
                <div key={index}>
                  <img src={photo} alt={`Photo ${index + 1}`} className="w-full h-64 object-cover rounded" />
                </div>
              ))}
            </Slider> */}
          </div>
          <div className="col-span-2">
            <label className="block text-sm font-bold text-gray-700">Terms</label>
            <p className="mt-1 text-gray-900">{data.terms}</p>
          </div>
          <div className="col-span-2">
            <label className="block text-sm font-bold text-gray-700">Amenities</label>
            <ul className="mt-1 text-gray-900 list-disc list-inside">
              {/* {data.amenities.map((amenity, index) => (
                <li key={index}>{amenity}</li>
              ))} */}
            </ul>
          </div>
          <div>
            <label className="block text-sm font-bold text-gray-700">Price</label>
            <p className="mt-1 text-gray-900">R{data.price}</p>
          </div>
        </div>
        <button
          onClick={handleBookClick}
          className="mt-6 px-4 py-2 bg-blue-600 text-white font-bold rounded hover:bg-blue-700"
        >
          Book
        </button>
      </div>
    );
};

export default Details